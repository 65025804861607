export interface Account {
  id: string,
  name: string,
  customerId: string,
  parentId?: string
}

export class AccountSupport {

  static isParent(account: Account) {
    return account.parentId === undefined || account.parentId === null;
  }

  static getParentAccounts(accounts: Account[]) {
    return accounts.filter(AccountSupport.isParent);
  }

  static getChildAccountsOf(accounts: Account[], parent: Account) {
    return accounts.filter(a => a.parentId === parent.id);
  }

  static compareByName(account1: Account, account2:Account) {
    return (account1?.name ?? '').localeCompare(account2?.name ?? '');
  }

  static getAccount(accounts: Account[], id: string) {
    return accounts.find(a => a.id === id);
  }
}
