import React from 'react';
import {useOutletContext, useParams} from "react-router-dom";
import { getAccount } from "../api/api";
import ChildAccounts from "./ChildAccounts";
import Heading from "./Heading";
import {OutletContext} from "./Root";
import {useQuery} from "@tanstack/react-query";
import Loader from "./Loader";
import {AccountSupport} from "../api/Account";
import AccountUsers from "./AccountUsers";
import AccountLicenses from "./AccountLicenses";
import AccountDetailHeader from "./AccountDetailHeader";

function AccountDetail() {

  const { accountId } = useParams();
  const { setMessageBoxContent } = useOutletContext() as OutletContext;

  const accountQuery = useQuery({
    queryKey: ['account', accountId],
    queryFn: () => getAccount(accountId!),
  })
  if (accountQuery.isError) {
    setMessageBoxContent(accountQuery.error);
    return null;
  }
  if (accountQuery.isLoading) {
    return (<Loader />);
  }

  return (
    <div className="divide-y divide-brand-gray-200">
      <div className="py-2">{
        <Heading headingText={accountQuery.data!.name}/>
      }</div>
      <div className="py-2">{
          <AccountDetailHeader account={accountQuery.data!}/>
      }</div>
      <div className="py-2">
        <AccountLicenses accountId={accountQuery.data!.id} accountName={accountQuery.data!.name} />
      </div>
      {/*TODO: <CreateLicenseForm/>*/}
      <div className="py-2">
        <ChildAccounts accountId={accountQuery.data!.id}
                       isParent={AccountSupport.isParent(accountQuery.data!)}
                       key={accountQuery.data!.id} />
      </div>
      <div className="py-2">
        <AccountUsers accountId={accountQuery.data!.id} />
      </div>
      {/*TODO: <CreateUserForm/>*/}
    </div>
  );
}

export default AccountDetail;
