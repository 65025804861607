const url = process.env.REACT_APP_STRIPE_DASHBOARD_URL;
if(url === null || url === undefined) {
  throw Error("REACT_APP_STRIPE_DASHBOARD_URL is not defined");
}

const stripe = {
  getCustomerUrl: function (customerId: string): string {
    return `${url}/customers/${customerId}`;
  },
  getSubscriptionUrl: function (subscriptionId: string): string {
    return `${url}/subscriptions/${subscriptionId}`;
  },
  getCustomerBalanceTransactionsUrl: function (customerId: string): string {
    return `${url}/customers/${customerId}/balance_transactions`;
  },
};

export default stripe;
