import React, { FC } from "react";
import Link from "./Link";
import {LicenseSeries} from "../api/License";

function getSeriesTemplateUrl(series: LicenseSeries): URL {
  return new URL(`https://git.cmpto.cz/transcoder/transcoder_test_tools/-/blob/master/trc_license_signing_tool/lt_templates/cfg_${series}.json?ref_type=heads`);
}

const SeriesInfoLink: FC<{
  series: LicenseSeries,
  className?: string
}> = ({ series, className }) => {
  const templateUrl = getSeriesTemplateUrl(series);
  const basename = templateUrl.pathname.split('/').pop();
  return (
    <Link href={templateUrl.href} className={className}
    >show license template ({basename})</Link>
  );
}

export default SeriesInfoLink;
