import React, { FC } from 'react';

const SubHeading: FC<{ headingText: string }> = ({headingText}) => {
  return (
    <div className="ml-4">
      <h3 className="font-center-light text-2xl">{headingText}</h3>
    </div>
  );
}

export default SubHeading;
