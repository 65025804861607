import React, {FC, ReactNode, useState} from 'react';
import ModalDialog from './ModalDialog';
import Heading from "./Heading";
import Input from "./Input";
import {useOutletContext} from "react-router-dom";
import Button from "./Button";
import Select from "./Select";
import Hint from "./Hint";
import {BusinessModel, Discount, License, LicenseSeries, Subscription} from "../api/License";
import {OutletContext} from "./Root";
import {useMutation} from "@tanstack/react-query";
import {activateSubscription} from "../api/api";
import Loader from "./Loader";

const ActivateSubscriptionButton: FC<{
  license: License,
  disabled?: boolean,
  children?: ReactNode
  onActivationSuccess?: (licenseId: string, activatedSubscription: Subscription) => void
}> = ({ license, disabled, children, onActivationSuccess} ) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Button title="Opens subscription activation dialog." onClick={openDialog} disabled={disabled}
      >{children}</Button>
      <ActivateSubscriptionDialog
        license={license}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        onActivationSuccess={onActivationSuccess}
      />
    </React.Fragment>
  );
};

const ActivateSubscriptionDialog: FC<{
  license: License,
  isOpen: boolean,
  onClose?: () => void
  onActivationSuccess?: (licenseId: string, activatedSubscription: Subscription) => void
}> = ({ license, isOpen, onClose, onActivationSuccess }) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const [ isDiscountApplied, setDiscountApplied ] = useState(false);

  const createSubscriptionMutation = useMutation({
    mutationFn: (data: FormData) => activateSubscription(
      FormFields.getLicenseId(data),
      FormFields.getSeries(data),
      FormFields.getBusinessModel(data),
      FormFields.getDiscount(data),
    ),
    onSuccess: (subscription, ) => {
      if (onClose) {
        onClose();
      }
      if (onActivationSuccess) {
        onActivationSuccess(license.licenseId, subscription);
      }
    },
    onError: (error) => {
      setMessageBoxContent(error);
    }
  });

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessageBoxContent(undefined);
    createSubscriptionMutation.mutate(new FormData(e.currentTarget));
  };

  return (
    <ModalDialog hasCloseBtn={true} isOpen={isOpen} onClose={onClose}>
      <Heading headingText={"Activate a new subscription"}/>
      <form onSubmit={submit}>
        <div className="grid grid-cols-[min-content_1fr] gap-1 items-center place-items-start my-4">
          <label>License key</label>
          <Input type="text" readOnly={true} value={license.licenseKey} size={37}/>
          <label>Series</label>
          <Input type="text" name={FormFields.series} readOnly={true} value={license.series}/>
          <input hidden={true} name={FormFields.licenseId} readOnly={true} value={license.licenseId} />
          <label>Model</label>
          <BusinessModelSelector name={FormFields.businessModel} />
          { !isDiscountApplied &&
            <React.Fragment>
              <label>Discount</label>
              <Input type="checkbox" name={FormFields.applyDiscount} checked={isDiscountApplied}
                     onChange={(e) => setDiscountApplied(e.currentTarget.checked)}
              />
            </React.Fragment>
          }
        </div>
          { isDiscountApplied &&
            <div className="grid grid-cols-[min-content_1fr] border p-2 mb-2">
              <label>Discount</label>
              <Input type="checkbox" name={FormFields.applyDiscount} checked={isDiscountApplied}
                     onChange={(e) => setDiscountApplied(e.currentTarget.checked)}
                     className="h-3.5 w-4"
              />
              <label>Amount</label>
              <span>
                <Input type="number" name={FormFields.discountAmount} defaultValue={10} step={0.01} min={0.01} />
                <DiscountAmountTypeSelector name={FormFields.discountAmountType} />
              </span>
              <span/><Hint className="ml-1">
                <p>Both % or absolute amount are applied to the whole invoice amount (not unit price) periodically each month (invoicing period).</p>
                <p>Absolute USD amount will be rounded to cents (2 decimal places).</p>
              </Hint>
              <label>Commitment credit</label>
              <span><Input type="number" name={FormFields.commitmentCredit} min={0.01} step={0.01} />[USD]</span>
              <span/><Hint className="ml-1">Leave empty for no credit.</Hint>
              <label>Duration</label>
              <span><Input type="number" name={FormFields.discountDuration} min={1} />[months]</span>
              <span/><Hint className="ml-1">Commitment and Discount duration, leave empty for no time limit.</Hint>
            </div>
          }
        <div className="flex gap-1 items-center">
          <Button type="submit" disabled={createSubscriptionMutation.isPending} >Create</Button>
          { createSubscriptionMutation.isPending && <Loader size={5} /> }
        </div>
      </form>
    </ModalDialog>
  );
};

const BusinessModelSelector: FC<React.HTMLProps<HTMLSelectElement>> = (props) => {
  const options = new Map([
    [ BusinessModel.PerHour, "Per hour"],
    [ BusinessModel.MaxPerMonth, "Max per month"],
  ]);
  return (
    <Select options={Array.from(options.keys())}
            idProvider={model => model}
            valueProvider={model => model}
            displayValueProvider={model => options.get(model)!}
            {...props} />
  );
};

enum DiscountAmountType {
  USD ='usd',
  Percent = 'percent'
}

const DiscountAmountTypeSelector: FC<{
  name: string
}> = ({ name }) => {
  const options = new Map([
    [ DiscountAmountType.USD, "USD"],
    [ DiscountAmountType.Percent, "%"]
  ]);
  return (
    <Select name={name} options={Array.from(options.keys())}
          valueProvider={(o) => o}
          displayValueProvider={(o) => options.get(o)!}
          idProvider={(o) => o}
          selectedOption={DiscountAmountType.Percent}
    />
  );
};

const FormFields = {
  licenseId: "licenseId",
  series: "series",
  businessModel: "businessModel",
  applyDiscount: "applyDiscount",
  discountAmount: "discountAmount",
  discountAmountType: "discountAmountType",
  discountDuration: "discountDuration",
  commitmentCredit: "commitmentCredit",

  getLicenseId: (data: FormData) => data.get(FormFields.licenseId) as string,
  getSeries: (data: FormData) => data.get(FormFields.series) as LicenseSeries,
  getBusinessModel: (data: FormData) => data.get(FormFields.businessModel) as BusinessModel,
  getDiscount: function (data: FormData): Discount | undefined {
    return FormFields.isDiscountRequired(data) ?
      {
        percentageBasisPoints: FormFields.getPercentageBasisPoints(data),
        fixedAmount: FormFields.getFixedAmount(data),
        durationMonths: FormFields.getDurationMonths(data),
        commitmentCredit: FormFields.getCommitmentCredit(data)
      } :
      undefined;
  },
  isDiscountRequired: (data: FormData): boolean => data.get(FormFields.applyDiscount) === 'on',
  getDiscountAmountType: (data: FormData): DiscountAmountType =>
    data.get(FormFields.discountAmountType) as DiscountAmountType,
  getDiscountAmount: function (data: FormData): number | undefined {
    const amount = data.get(FormFields.discountAmount) as string;
    return amount && amount.length > 0 ? parseFloat(amount) : undefined;
  },
  getPercentageBasisPoints: function (data: FormData): number | undefined {
    const amountType = FormFields.getDiscountAmountType(data);
    if (amountType !== DiscountAmountType.Percent) {
      return undefined;
    }
    const amount = FormFields.getDiscountAmount(data);
    return amount ? Math.round(amount * 100) : undefined;
  },
  getFixedAmount: function (data: FormData): number | undefined {
    const amountType = FormFields.getDiscountAmountType(data);
    if (amountType !== DiscountAmountType.USD) {
      return undefined;
    }
    const amount = FormFields.getDiscountAmount(data);
    return amount ? Math.round(amount * 100) : undefined;
  },
  getDurationMonths: function (data: FormData) {
    const duration = data.get(FormFields.discountDuration) as string;
    return duration && duration.length > 0 ?
      parseInt(duration) : undefined;
  },
  getCommitmentCredit: function (data: FormData) {
    const credit = data.get(FormFields.commitmentCredit) as string;
    return credit && credit.length > 0 ?
      Math.round(parseFloat(credit) * 100) :
      undefined;
  }
};

export default ActivateSubscriptionButton;
