import React, { FC, useEffect, useState } from 'react';
import AllAccountsTable from "./AllAccountsTable";
import CreateAccountForm from "./CreateAccountForm";
import { getAccounts } from "../api/api";
import { Account } from "../api/Account";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "./Root";
import Loader from "./Loader";

export const GetAccountsQueryKey = ["accounts"];

const AllAccounts: FC = () => {
  const { isLoading, setIsLoading, setMessageBoxContent } = useOutletContext() as OutletContext;
  const [accounts, setAccounts] = useState<Account[]>([]);
  useEffect(() => {
    getAccounts()
      .then(
        (data) => {
          setIsLoading(false);
          setAccounts(data);
          setMessageBoxContent(undefined)
        },
        (error: Error) => {
          setIsLoading(false);
          setMessageBoxContent(error);
        }
      )
  }, [setIsLoading, setMessageBoxContent])

  const addAccount = (account: Account) => {
    setAccounts([...accounts, account]);
  }

  return (
    <div>
      { isLoading ? <Loader/> : <AllAccountsTable accounts={accounts}/> }
      <hr className="my-3"/>
      <CreateAccountForm
        accounts={accounts}
        addAccount={addAccount}
      />
    </div>
  );
}

export default AllAccounts;
