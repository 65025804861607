import React, { FC, forwardRef, useRef } from 'react';
import Input from "./Input";
import Button from "./Button";

type ValidTillInputProps = {
  name: string,
  required?: boolean,
  className?: string,
}

const ValidTillInput = forwardRef(
  (props: ValidTillInputProps, ref: React.ForwardedRef<HTMLInputElement> | undefined) => {
    return (
      <Input type="date" name={props.name} title="Valid till" required={props.required} max="9999-12-31"
             className={props.className} ref={ref}/>
    )
  });

export const ValidTillInputWithPresets: FC<{
  name: string,
  required?: boolean,
  className?: string,
}> = ({ name, required = true }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = (value: string) => {
    if (inputRef.current) inputRef.current!.value = value
  };

  return (
    <div className="flex gap-1">
      <ValidTillInput name={name} required={required} ref={inputRef}/>
      <Button onClick={() => onClick(getValidTillValue(twoWeeksMillis))}>
        14 days
      </Button>
      <Button onClick={() => onClick(getValidTillValue(oneMonthMillis))}>1 month</Button>
      <Button onClick={() => onClick(getValidTillValue(oneYearMillis))}>1 year</Button>
      <Button onClick={() => onClick(getPerpetualValidTillValue())}>Perpetual</Button>
    </div>
  )
};

const twoWeeksMillis = 2 * 7 * 24 * 60 * 60 * 1000;
const oneMonthMillis = 30.4375 * 24 * 60 * 60 * 1000;
const oneYearMillis = 365.2422 * 24 * 60 * 60 * 1000;

function getValidTillValue(delayFromNowMillis: number) {
  const date = new Date(Date.now() + delayFromNowMillis);
  return date.toISOString().slice(0, 10);
}

function getPerpetualValidTillValue() {
  const date = new Date('2300-01-01');
  return date.toISOString().slice(0, 10);
}

export default ValidTillInput;
