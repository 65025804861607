import React, {FC, ReactNode} from "react";
import stripe from "../api/stripe";
import Link from "./Link";

export const StripeCustomerLink: FC<{
  customerId: string
  children: ReactNode;
}> = ({ customerId, children}) => (
  <Link href={stripe.getCustomerUrl(customerId)}>{children}</Link>
);

export default StripeCustomerLink;
