import React, { FC, ReactNode } from 'react';
import { CopyButton } from "./Button";

const CopyableText: FC<{ text: string, children?: ReactNode }> = ({ text, children}) => (
  <div className="flex gap-2 items-center justify-between">
    {children ? children : <span>{text}</span>}
    <CopyButton valueToCopy={text}/>
  </div>
)

export default CopyableText;
