import React, { FC } from 'react';

const Heading: FC<{ headingText: string }> = ({headingText}) => {
  return (
    <div className="mx-3 mt-2">
      <h1 className="font-center-light text-3xl">{headingText}</h1>
    </div>
  );
}

export default Heading;
