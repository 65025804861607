import React, { FC, ReactNode, useState } from "react";
import StagingWarning from "./StagingWarning";
import { Outlet } from "react-router-dom";
import MessageBox from "./MessageBox";

type MessageBoxContentType = string | Error | ReactNode | undefined;
export type MessageBoxContentSetterType = (content: MessageBoxContentType, hideAfter?: number) => void

export type OutletContext = {
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
  setMessageBoxContent: MessageBoxContentSetterType
}

const Root: FC = () => {
  // TODO isLoading might not be needed if we use loader and CommonSuspense for all data fetching.
  const [isLoading, setIsLoading] = useState(true);
  const [messageBoxContent, setMessageBoxContentState] =
    useState<MessageBoxContentType>(undefined);
  const setMessageBoxContent: MessageBoxContentSetterType = (content, hideAfter = 0) => {
    setMessageBoxContentState(content);
    if (hideAfter > 0) {
      setTimeout(() => setMessageBoxContentState(undefined), hideAfter);
    }
  }
  const onMessageClose = () => setMessageBoxContent(undefined);

  return (
    <div className="ml-2 mb-2">
      {isStagingEnvironment() && <StagingWarning/>}
      <Outlet context={{ isLoading, setIsLoading, setMessageBoxContent } satisfies OutletContext}/>
      {
        messageBoxContent !== undefined ? (
          messageBoxContent instanceof Error
            ? <MessageBox onClose={onMessageClose} colorClass="text-brand-red-600">
                {messageBoxContent.message}
              </MessageBox>
            : <MessageBox onClose={onMessageClose} colorClass="text-brand-gray-600">
                {messageBoxContent}
              </MessageBox>
        ) : <></>
      }
    </div>
  );
}

function isStagingEnvironment(): boolean {
  return process.env.REACT_APP_ENVIRONMENT === "staging";
}

export default Root;
