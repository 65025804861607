import React, { FC } from "react";
import Select from "./Select";
import { LicenseSeries } from "../api/License";

const SeriesSelector: FC<{
  selected: LicenseSeries,
  name?: string,
  unknownSeries?: boolean,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>,
  className?: string,
}> = ({ selected, name, unknownSeries = false, onChange, className }) => {
  let options = (Object.values(LicenseSeries) as string[]);
  if (unknownSeries) options.push("Unknown")
  return (
    <Select options={options}
            idProvider={s => s}
            valueProvider={s => s}
            displayValueProvider={s => s}
            name={name}
            selectedOption={selected}
            onChange={onChange}
            className={className}/>
  );
}

export default SeriesSelector;
