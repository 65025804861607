import React, {FC, HTMLProps} from 'react';
import clsx from "clsx";

const Hint: FC<HTMLProps<HTMLSpanElement>> =
  (props) => (
    <span {...props}
          className={clsx("inline-block text-brand-gray-600 text-xs whitespace-normal", props.className)}
    >{props.children}</span>
);

export default Hint;
