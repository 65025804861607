import React, { FC } from "react";
import Select from "./Select";

const DockerHubRepoSelector: FC<{
  selected: string,
  name?: string,
  unknownSeries?: boolean,
  className?: string,
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}> = ({ selected, name, className, onChange }) => {
  //let options = (Object.values(LicenseSeries) as string[]);
  let options = ["comprimato/live-transcoder","comprimato/live-transcoder-experimental"];
  return (
    <Select options={options}
            idProvider={s => s}
            valueProvider={s => s}
            displayValueProvider={s => s}
            name={name}
            selectedOption={selected}
            className={className}
            onChange={onChange}/>
  );
}

export default DockerHubRepoSelector;
