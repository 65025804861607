import React, {FC, FormEvent, useRef, useState} from 'react';
import {Account, AccountSupport} from "../api/Account";
import AccountSelector, {NoneAccount} from "./AccountSelector";
import Loader from "./Loader";
import {createAccount} from "../api/api";
import SubHeading from "./SubHeading";
import Input from "./Input";
import { SubmitButton } from "./Button";
import Hint from "./Hint";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "./Root";

const formFieldAccountName = "account_name";
const formFieldParentAccountId = "parent_account";

const CreateAccountForm: FC<{
  accounts: Account[],
  addAccount: (account: Account) => void,
}> = ({ accounts, addAccount }) => {

  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = function (event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    setMessageBoxContent(undefined);
    const [ accountName, parentAccountId ] = getFormData(event.currentTarget);
    createAccount( accountName!, parentAccountId)
      .then(
        (account) => {
          setIsLoading(false);
          addAccount(account);
          formRef.current!.reset();
        },
        (error) => {
          setIsLoading(false);
          setMessageBoxContent(error)
        }
      );
  }

  const parentAccounts = AccountSupport.getParentAccounts(accounts)
    .sort(AccountSupport.compareByName);

  return (
    <div>
      <form ref={formRef} onSubmit={handleSubmit}>
        <SubHeading headingText="Create new account"/>
        <div className="leading-none">
          <ul>
            <li><Hint>Account can have multiple licenses</Hint></li>
            <li><Hint>Account without a parent account has 1 Stripe account (single invoice e-mail)</Hint></li>
            <li><Hint>Account with a parent account uses the parent's Stripe account</Hint></li>
            <li><Hint>Account has multiple users (accesses to customer dashboard)</Hint></li>
            <li><Hint>Usually corresponds to a single company/vendor</Hint></li>
          </ul>
        </div>
        <br/>
        <label>
          Name
          <Input name={formFieldAccountName} pattern=".*\w.*" title="Has to contain at least a single letter."/>
          <Hint>Must be unique.</Hint>
        </label>
        <div className="mt-1">
          <label>
            Parent (reseller's) account
            <AccountSelector name={formFieldParentAccountId}
                             accounts={parentAccounts}
                             selected={NoneAccount}
                             valueProvider={a => a.id}
            />
            <Hint>Optional, tie the new account with a parent (reseller's) account. Reseller's stripe customer is used for billing then.</Hint>
          </label>
        </div>
        <div className="flex items-center">
          <SubmitButton text="Create"/>
          { isLoading && <Loader className="ml-2" size={5}/> }
        </div>
      </form>
    </div>
  );
}

function getFormData(form: HTMLFormElement) {
  const formData = new FormData(form);
  const parentAccountId = formData.get(formFieldParentAccountId) as string;
  return [
    formData.get(formFieldAccountName) as string,
    parentAccountId && parentAccountId.length > 0 ? parentAccountId : undefined
  ];
}

export default CreateAccountForm;
