import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import AllAccounts from "./components/AllAccounts";
import { RouteErrorElement } from "./components/RouteErrorElement";
import Root from "./components/Root";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AccountDetail from "./components/AccountDetail";

const router = createBrowserRouter([
  {
    element: <Root />,
    path: "/",
    children: [
      {
        index: true,
        element: <AllAccounts />,
        errorElement: <RouteErrorElement />,
      },
      {
        path: "accounts/:accountId",
        element: <AccountDetail />,
        errorElement: <RouteErrorElement />,
      },
    ]
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
