import React, {FC, useState} from 'react';
import Table from "./Table";
import Button from "./Button";
import User from "../api/User";
import CopyableText from "./CopyableText";
import {useMutation} from "@tanstack/react-query";
import {resendInvitationEmail} from "../api/api";
import {useOutletContext} from "react-router-dom";
import {OutletContext} from "./Root";
import Loader from "./Loader";

const UsersTable: FC<{
  users: User[],
}> = ({ users }) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const [ pendingInvitationEmails, setPendingInvitationEmails ] = useState<string[]>([]);
  const pendingInvitations = {
    add: (email: string) => setPendingInvitationEmails([...pendingInvitationEmails, email]),
    remove: (email: string) => setPendingInvitationEmails(pendingInvitationEmails.filter(e => e !== email)),
    contains: (email: string) => pendingInvitationEmails.includes(email)
  };
  const resendInvitationMutation = useMutation({
    mutationFn: (email: string) => {
      pendingInvitations.add(email);
      return resendInvitationEmail(email).then(() => email);
    },
    onSuccess: (email) => {
      setMessageBoxContent(`Invitation e-mail was sent to ${email}.`, 3000);
    },
    onError: (error) => {
      setMessageBoxContent(error);
    },
    onSettled: (_, __, email) =>
      pendingInvitations.remove(email)
  });
  return (
      <Table>
        <Table.Header>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {
            users.map((u) =>
            <Table.Row key={u.id}>
              <Table.Cell>
                <CopyableText text={u.email}/>
              </Table.Cell>
              <Table.Cell className="flex items-center">
                <Button onClick={() => resendInvitationMutation.mutate(u.email)}
                        disabled={pendingInvitations.contains(u.email)}
                >Resend invitation email</Button>
                { pendingInvitations.contains(u.email) && <Loader size={5} className="ml-2" /> }
              </Table.Cell>
            </Table.Row>)
          }
        </Table.Body>
      </Table>
  );
}

export default UsersTable;
