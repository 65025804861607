import { FC, FunctionComponentElement, ReactElement, ReactNode } from "react";
import clsx from "clsx";

type TableHeaderProps = {
  children?: ReactNode;
  className?: string;
  title?: string;
};

const HeaderCell: FC<TableHeaderProps> = ({children, className, title}): ReactElement => {
  return (
    <th
      scope="col"
      className={clsx(
        'whitespace-nowrap px-6 py-4 text-left font-normal uppercase text-brand-gray-500',
        className
      )}
      title={title}
    >
      {children}
    </th>
  );
};

type TableProps<T> = {
  children: (
    | FunctionComponentElement<TableHeaderProps>
    | FunctionComponentElement<T>
    )[];
} & { Header?: FC } & {
  Cell?: FC;
} & { Row?: ReactElement<typeof Row> } & {
  Body?: FC;
} & {
  HeaderCell?: FC<TableHeaderProps>;
};

const Table = <T, >({children}: TableProps<T>): ReactElement => {
  return (
    <div className="relative flex flex-col">
      <div className="inline-block min-w-full -my-2 py-2 align-middle">
        <table className="table-fixed">{children}</table>
      </div>
    </div>
  );
};

const Header: FC<TableHeaderProps> = ({children}) => {
  return (
    <thead className="bg-brand-gray-100 text-sm">
    <tr>{children}</tr>
    </thead>
  );
};

const Body: FC<{ children?: ReactNode }> = ({children}) => (
  <tbody className="divide-y divide-gray-200 bg-white text-sm">
  {children}
  </tbody>
);

export type TableCellProps = {
  colSpan?: number;
  className?: string;
  children?: ReactNode;
}

const Cell: FC<TableCellProps> = ({className, children, colSpan}) => (
  <td
    className={clsx('whitespace-nowrap px-3 py-2', className)}
    colSpan={colSpan}
  >
    {children}
  </td>
);

const MultiRowCell: FC<TableCellProps> = ({children}) => (
  <Cell>
    <div className="flex flex-col">{children}</div>
  </Cell>
);

export type RowChildren = ReactElement<typeof Cell> | ReactElement<typeof Cell>[];
type TableRowProps = {
  children: RowChildren;
}

const Row: FC<TableRowProps> = ({children}): ReactElement => {
  return (
    <tr className="hover:bg-brand-gray-100">
      {children}
    </tr>
  );
};

Table.Header = Header;
Table.HeaderCell = HeaderCell;
Table.Body = Body;
Table.Cell = Cell;
Table.MultiRowCell = MultiRowCell;
Table.Row = Row;

export default Table;
