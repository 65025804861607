import React, { FC, ReactNode, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "./Root";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deactivateSubscription,
  deleteLicense,
  getAccountLicenses,
  getAccounts,
  patchLicense,
  setupLicense
} from "../api/api";
import Loader from "./Loader";
import LicensesTable from "./LicensesTable";
import SubHeading from "./SubHeading";
import Input from "./Input";
import Button from "./Button";
import { License, LicenseSeries, PatchLicenseParams } from "../api/License";
import SeriesSelector from "./SeriesSelector";
import { ValidTillInputWithPresets } from "./ValidTillInput";
import MaxPipelinesInput from "./MaxPipelinesInput";
import LicenseKeyInput from "./LicenseKeyInput";
import { AccountSupport } from "../api/Account";
import { GetAccountsQueryKey } from "./AllAccounts";
import { AccountDetailsLink } from "./AllAccountsTable";
import SeriesInfoLink from "./SeriesInfoLink";

function newArrayWithElementAtIndex<T>(array: T[], index: number, element: T): T[] {
  return [
    ...array.slice(0, index),
    element,
    ...array.slice(index + 1)
  ]
}

function getLicenseIndex(licenses: License[], licenseId: string): number {
  const licenseIndex = licenses.findIndex((l) => l.licenseId === licenseId)!;
  if (licenseIndex === -1) {
    throw new Error(`License ${licenseId} is missing`);
  }
  return licenseIndex;
}

export const AccountLicenses: FC<{
  accountId: string,
  accountName: string,
}> = ({ accountId, accountName }) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const { isPending, isError, data, error } = useQuery({
    queryKey: buildQueryKey(accountId),
    queryFn: () => getAccountLicenses(accountId!),
  })
  const accountsQuery = useQuery({
    queryKey: GetAccountsQueryKey,
    queryFn: () => getAccounts().then(accounts => accounts.sort(AccountSupport.compareByName)),
  })

  const queryClient = useQueryClient();
  const deactivateSubscriptionMutation = useMutation({
    mutationFn: (licenseId: string) => deactivateSubscription(licenseId),
    onSuccess: (_, licenseId: string) => queryClient.setQueryData(
      buildQueryKey(accountId),
      (data: License[]) => {
        const licenseWithDeactivatedSubscriptionIndex = getLicenseIndex(data, licenseId);
        const license = {
          ...data[licenseWithDeactivatedSubscriptionIndex],
          subscription: { isActive: false }
        };
        return newArrayWithElementAtIndex(data, licenseWithDeactivatedSubscriptionIndex, license);
      }
    ),
    onError: (error) => {
      setMessageBoxContent(error);
    }
  })
  const patchLicenseMutation = useMutation({
    mutationFn: (params: PatchLicenseParams) => patchLicense(params),
    onSuccess: (license: License) => queryClient.setQueryData(
      buildQueryKey(accountId),
      (data: License[]) => {
        if (license.account?.id !== accountId) {
          // License moved to a new account
          setMessageBoxContent(
            <span>License has been transferred to account <AccountDetailsLink account={license.account!}/></span>
          )
          return data.filter((l) => l.licenseId !== license.licenseId);
        }
        const patchedLicenseIndex = getLicenseIndex(data, license.licenseId);
        return newArrayWithElementAtIndex(data, patchedLicenseIndex, license);
      }
    ),
    onError: (error) => {
      setMessageBoxContent(error);
    }
  })
  const deleteLicenseMutation = useMutation({
    mutationFn: (licenseId: string) => deleteLicense(licenseId).then(() => licenseId),
    onSuccess: (licenseId: string) => queryClient.setQueryData(
      buildQueryKey(accountId),
      (data: License[]) => data.filter((l) => l.licenseId !== licenseId)
    ),
    onError: (error) => {
      setMessageBoxContent(error);
    }
  })

  async function awaitWithErrorHandling<T>(f: () => Promise<T>): Promise<T | void> {
    try {
      return await f();
    } catch (e) {
      setMessageBoxContent(e instanceof Error ? e : new Error(String(e)));
    }
  }
  const onPatchLicense = (params: PatchLicenseParams) =>
    awaitWithErrorHandling(() => patchLicenseMutation.mutateAsync(params)) as Promise<License>
  const onDeleteLicense = (licenseId: string) =>
    awaitWithErrorHandling(() => deleteLicenseMutation.mutateAsync(licenseId)) as Promise<void>
  const onDeactivateSubscription = (licenseId: string) =>
    awaitWithErrorHandling(() => deactivateSubscriptionMutation.mutateAsync(licenseId)) as Promise<void>

  if (isError || accountsQuery.isError) {
    setMessageBoxContent(error || accountsQuery.error!);
    return <></>;
  }
  return (
    <React.Fragment>
      <SubHeading headingText="Account Licenses"/>
      { isPending
        ? <Loader/>
        : <LicensesTable licenses={data!}
                         accounts={accountsQuery.data!}
                         onPatchLicense={onPatchLicense}
                         onDeleteLicense={onDeleteLicense}
                         onDeactivateSubscription={onDeactivateSubscription}
          />
      }
      <div className="mt-2 ml-2">
        <SetupLicenseForm accountName={accountName} />
      </div>
    </React.Fragment>
  );
}

const NoWrapLabel: FC<{ children: ReactNode }> = ({ children }) => (
  <label className="whitespace-nowrap">{children}</label>
)

const SetupLicenseForm: FC<{
  accountName: string
}>= ({ accountName }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const setupLicenseMutation = useMutation({
    mutationFn: (data: FormData) => {
      const validTillDate = new Date(FormFields.getValidTill(data));
      validTillDate.setUTCHours(23, 59, 59);
      return setupLicense(
        FormFields.getLicenseKey(data),
        FormFields.getSeries(data),
        FormFields.getDescription(data),
        FormFields.getMaxPipelines(data),
        validTillDate,
        FormFields.getAccountName(data)
      )
    },
    onSuccess: (license) => {
      queryClient.setQueryData(
        buildQueryKey(license.account!.id),
        (data: License[]) => [ ...data, license ]
      );
      formRef.current!.reset();
    },
    onError: (error) => {
      setMessageBoxContent(error);
    }
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessageBoxContent(undefined);
    setupLicenseMutation.mutate(new FormData(e.currentTarget));
  };

  const onReset = (e: React.FormEvent<HTMLFormElement>) => {
    (e.currentTarget.elements.namedItem(FormFields.validTill) as HTMLInputElement).value = "";
  }

  const [selectedSeries, setSelectedSeries] = useState(LicenseSeries.Series300)
  const handleSeriesChange = function(event: React.FormEvent<HTMLSelectElement>) {
    setSelectedSeries(event.currentTarget.value as LicenseSeries);
  }

  return (
    <React.Fragment>
      <form ref={formRef} onSubmit={onSubmit} onReset={onReset}>
        <div className="grid grid-cols-[min-content_1fr] grid-rows-5 gap-1 items-center place-items-start">
          <NoWrapLabel>Description</NoWrapLabel>
          <Input required={true} name={FormFields.description} title="Has to contain at least a single letter." className="w-80"/>
          <NoWrapLabel>Series</NoWrapLabel>
          <span>
            <SeriesSelector name={FormFields.series} selected={selectedSeries} onChange={handleSeriesChange}/>
            <SeriesInfoLink series={selectedSeries} className="text-xs text-brand-gray-500"/>
          </span>
          <NoWrapLabel>Valid till (UTC)</NoWrapLabel>
          <ValidTillInputWithPresets required={true} name={FormFields.validTill}/>
          <NoWrapLabel>Max pipelines</NoWrapLabel>
          <MaxPipelinesInput required={true} name={FormFields.maxPipelines} className="w-16"/>
          <NoWrapLabel>Existing license key</NoWrapLabel>
          <LicenseKeyInput required={false} name={FormFields.licenseKey}/>
        </div>
        <input type="hidden" name={FormFields.accountName} value={accountName} />
        <div className="flex gap-1 items-center">
          <Button type="submit" disabled={setupLicenseMutation.isPending}
                  title="Setup a new license.">Setup</Button>
          {setupLicenseMutation.isPending && <Loader size={5} className="ml-2"/>}
        </div>
      </form>
    </React.Fragment>
  );
}

const FormFields = {
  description: "description",
  series: "series",
  validTill: "validTill",
  maxPipelines: "maxPipelines",
  licenseKey: "licenseKey",
  accountName: "accountName",
  getDescription: (data: FormData) => data.get(FormFields.description) as string,
  getSeries: (data: FormData) => data.get(FormFields.series) as LicenseSeries,
  getValidTill: (data: FormData) => data.get(FormFields.validTill) as string,
  getMaxPipelines: (data: FormData) => parseInt(data.get(FormFields.maxPipelines) as string),
  getLicenseKey: (data: FormData) => data.get(FormFields.licenseKey) as string || undefined,
  getAccountName: (data: FormData) => data.get(FormFields.accountName) as string,
};

function buildQueryKey(accountId: string) {
  return ['account-licenses', accountId];
}

export default AccountLicenses;
