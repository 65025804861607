import React, { FC } from "react";
import { Account } from "../api/Account";
import Select from "./Select";

export const NoneAccount = { id: "", name: "None", customerId: "" }

const AccountSelector: FC<{
  name?: string,
  accounts: Account[],
  valueProvider: (account: Account) => string,
  selected?: Account,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>,
  className?: string,
}> = ({ name, accounts, valueProvider, selected, onChange, className }) => {
  let options: Account[] = [...accounts, NoneAccount];
  return (
    <Select options={options}
            idProvider={a => a.id}
            valueProvider={valueProvider}
            displayValueProvider={a => a.name}
            name={name}
            selectedOption={selected}
            onChange={onChange}
            className={className}
    />
  );
}

export default AccountSelector;
