import React from 'react';
import { useOutletContext, useRouteError } from "react-router-dom";
import { OutletContext } from "./Root";

/**
 * Catches errors in routes and displays them in the MessageBox.
 * It's expected to be used as the `errorElement` parameter of a RouteObject.
 * @returns JSX.Element Since we display errors in the message box at the bottom, this returns an empty element.
 */
export function RouteErrorElement() {
  const error = useRouteError() as Error | undefined;
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  setMessageBoxContent(error);
  return <></>
}
