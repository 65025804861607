import React, {useState} from 'react';
import ModalDialog from './ModalDialog';
import {getDockerComposeUrl} from "../api/api";
import CopyableText from "./CopyableText";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import DockerHubRepoSelector from "./DockerHubRepoSelector";
import Input from "./Input";
import Link from "./Link";
import { WgetButton } from "./Button";


interface DockerComposeModalData {
    tag: string;
    repo: string;
}

const initialDockerComposeModalData: DockerComposeModalData = {
    tag: 'latest',
    repo: 'comprimato/live-transcoder'
};

interface DockerComposeModalProps {
    licenseKey: string;
    isOpen: boolean;
    onClose: () => void;
}

const DockerComposeModalDialog: React.FC<DockerComposeModalProps> = ({
                                                             licenseKey,
                                                             isOpen,
                                                             onClose,
                                                         }) => {

    const [dialogState, setDialogState] = useState<DockerComposeModalData>(
        initialDockerComposeModalData
    );

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { name, value } = event.target;
        setDialogState({...dialogState,
            [name]: value,
        });
    };

    const handleSelectChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const { name, value } = event.target;
        setDialogState({...dialogState,
            [name]: value,
        });
    };

    const dockerComposeUrl = getDockerComposeUrl(licenseKey, dialogState.repo, dialogState.tag);

    return (
        <ModalDialog
            hasCloseBtn={true}
            isOpen={isOpen}
            onClose={onClose}>
            <Heading headingText={"Generate docker-compose.yml download URL"}/>
            <div className="ml-6">
              <span className="whitespace-normal text-xs text-brand-orange-500">
                The docker-compose.yml URL or wget command at the Customer Dashboard always link to
                the <code>comprimato/live-transcoder:latest</code> image. This dialog does not affect the Customer
                Dashboard behavior in any way.
              </span>
            </div>
            <div className="mt-2">
                <SubHeading headingText={"Repository"}/>
                <DockerHubRepoSelector name="repo" selected={dialogState.repo} onChange={handleSelectChange} className="ml-6 mt-2"/>
            </div>
            <div className="mt-2">
                <SubHeading headingText={"Tag"}/>
                <Input name="tag" title="tag" defaultValue={dialogState.tag} onChange={handleInputChange} className="ml-6 mt-2"/>
            </div>
            <div className="mt-2">
                <SubHeading headingText={"URL"}/>
                <div className="ml-6 mt-2 whitespace-normal flex gap-1 items-center justify-between">
                  <CopyableText text={dockerComposeUrl}>
                    <Link href={dockerComposeUrl}>{dockerComposeUrl}</Link>
                  </CopyableText>
                  <WgetButton url={dockerComposeUrl}/>
                </div>
            </div>
        </ModalDialog>
    );
};

export default DockerComposeModalDialog;
