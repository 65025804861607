import React, { FC, ReactNode } from "react";
import stripe from "../api/stripe";
import Link from "./Link";

export const StripeSubscriptionLink: FC<{
  subscriptionId: string;
  children: ReactNode;
}> = ({ subscriptionId, children }) => (
  <Link href={stripe.getSubscriptionUrl(subscriptionId)}>{children}</Link>
);

export default StripeSubscriptionLink;
