import {Account} from "./Account";
import License, {LicenseSeries, PatchLicenseParams, BusinessModel, Discount, Subscription} from "./License";
import User from "./User";

function getLocalStorageAccessToken(): string | null {
  return localStorage.getItem('accessToken');
}

function setLocalStorageAccessToken(accessToken: string) {
  localStorage.setItem('accessToken', accessToken);
}

function getUrlAccessToken(): string | null {
  return new URLSearchParams(window.location.hash.substring(1)).get('access_token');
}

export function resolveAccessToken(): string | null {
  let token = getUrlAccessToken();
  if (token === null) {
    token = getLocalStorageAccessToken();
  }
  else {
    setLocalStorageAccessToken(token);
  }
  return token;
}

function clearAccessToken() {
  localStorage.removeItem('accessToken');
}

function doLogin(redirectUri?: string) {
  clearAccessToken();
  visitCognito(redirectUri);
}

function visitCognito(redirectUri?: string) {
  redirectUri = redirectUri || window.location.pathname;
  const absoluteRedirectUri = window.location.origin + redirectUri;
  window.location.assign(process.env.REACT_APP_COGNITO_URL + '&redirect_uri=' + encodeURIComponent(absoluteRedirectUri));
}

// TODO use above functions from the common.js

const apiUrl: string | undefined = process.env.REACT_APP_MONEYBOX_API_URL;
if (!apiUrl) {
  throw Error("REACT_APP_MONEYBOX_API_URL is not defined");
}

const apiAccountsUrl = `${apiUrl}/accounts`

export async function getAccounts(): Promise<Account[]> {
  return callApiGet(apiAccountsUrl);
}

export async function getAccount(accountId: string): Promise<Account> {
  return callApiGet(`${apiAccountsUrl}/${accountId}`);
}

export async function createAccount(name: string, parentAccountId?: string): Promise<Account> {
  const body = JSON.stringify({
    name: name,
    parentAccountId: parentAccountId
  });
  return callApiPost(apiAccountsUrl, body);
}

export async function setupLicense(
  licenseKey: string | undefined,
  series: LicenseSeries,
  description: string,
  maxPipelines: number,
  validTill: Date,
  accountName: string
): Promise<License> {
  const body = JSON.stringify({
    licenseKey,
    series,
    description,
    maxPipelines,
    validTill: validTill.toISOString(),
    accountName
  });
  return callApiPost(`${apiUrl}/licenses`, body);
}

export async function patchLicense(
  {
    licenseId,
    series,
    description,
    maxPipelines,
    validTill,
    accountId
  }: PatchLicenseParams
): Promise<License> {
  const body = JSON.stringify({
    series,
    description,
    maxPipelines,
    validTill: validTill?.toISOString(),
    accountId
  });
  return callApiPatch(`${apiUrl}/licenses/${licenseId}/patch`, body);
}

export async function deleteLicense(licenseId: string): Promise<void> {
  return callApiDelete(`${apiUrl}/licenses/${licenseId}`);
}

export async function activateSubscription(
  licenseId: string,
  series: LicenseSeries,
  businessModel: BusinessModel,
  discount?: Discount
): Promise<Subscription> {
  const body = JSON.stringify({
    series,
    businessModel,
    discount
  });
  return callApiPost(`${apiUrl}/licenses/${licenseId}/subscription`, body);
}

export async function deactivateSubscription(licenseId: string): Promise<void> {
  return callApiDelete(`${apiUrl}/licenses/${licenseId}/subscription`);
}

export async function getChildAccounts(parentAccountId: string): Promise<Account[]> {
  return callApiGet(`${apiAccountsUrl}/${parentAccountId}/children`);
}

export async function getAccountLicenses(accountId: string): Promise<License[]> {
  return callApiGet(`${apiAccountsUrl}/${accountId}/licenses`);
}

export async function getAccountUsers(accountId: string): Promise<User[]> {
  return callApiGet(`${apiAccountsUrl}/${accountId}/users`);
}

export async function createUser(email: string, accountId: string): Promise<User> {
  const body = JSON.stringify({
    accountId,
    userEmail: email
  });
  return callApiPost(`${apiUrl}/users`, body);
}

export async function resendInvitationEmail(email: string): Promise<void> {
  const body = JSON.stringify({
    userEmail: email
  });
  return callApiPost(`${apiUrl}/users/resend-invitation-email`, body);
}

async function callApiGet<ResultType>(endpoint: string): Promise<ResultType> {
  return callApi(endpoint, "GET", undefined);
}

async function callApiPost<ResultType>(endpoint: string, body: BodyInit): Promise<ResultType> {
  return callApi(endpoint, "POST", body);
}

async function callApiPatch<ResultType>(endpoint: string, body: BodyInit): Promise<ResultType> {
  return callApi(endpoint, "PATCH", body);
}

async function callApiDelete<ResultType>(endpoint: string): Promise<ResultType> {
  return callApi(endpoint, "DELETE", undefined);
}

async function callApi<ResultType>(
  endpoint: string,
  method: "GET" | "POST" | "PATCH" | "DELETE",
  body: BodyInit | undefined
): Promise<ResultType> {
  const accessToken = resolveAccessToken();
  if (accessToken === null) {
    return Promise.reject("Access token is not defined");
  }
  let headers: {[header: string] : string} = { "Authorization": `Bearer ${accessToken}` };
  if (body) headers = { ...headers, "Content-Type": "application/json" };
  const response = await fetch(endpoint, {
    method,
    headers,
    body
  });
  if (response.status === 204) return null as ResultType;
  return processJsonApiResponse<ResultType>(response);
}

// TODO: extract and call the common.js
async function processJsonApiResponse<ResultType>(response: Response): Promise<ResultType> {
  await checkApiResponseError(response);
  return response.json();
}

async function checkApiResponseError(response: Response): Promise<void> {
  if (!response.ok) {
    const status = response.status;
    const text = await response.text();
    if (status === 401) {
      doLogin();
    }
    throw new Error('API returned status code ' + status + '. ' + text);
  }
}

export function getDockerComposeUrl(licenseKey: string , repo: string, tag: string) {
  if (apiUrl !== undefined) {
    return `${apiUrl}/licenses/docker-compose.yml?license_key=${licenseKey}&docker_image_reference=${repo}:${tag}`;
  } else {
    return 'Undefined';
  }
}
