import React, { FC, useEffect, useState } from 'react';
import Table from "./Table";
import StripeSubscriptionLink from "./StripeSubscriptionLink";
import Button, {DockerComposeButton, LicenseMonitoringButton, LicenseStatsButton} from "./Button";
import License, { PatchLicenseParams, Subscription } from "../api/License";
import CopyableText from "./CopyableText";
import Input from "./Input";
import SeriesSelector from "./SeriesSelector";
import Loader from "./Loader";
import AccountSelector from "./AccountSelector";
import { Account } from "../api/Account";
import Link from "./Link";
import stripe from "../api/stripe";
import ActivateSubscriptionButton from "./ActivateSubscriptionButton";

const SubscriptionLink: FC<{subscription: Subscription}> = ({ subscription }) => {
  return (
    subscription.isActive
      ? <div>
          <StripeSubscriptionLink subscriptionId={subscription.subscriptionId!!}>Active</StripeSubscriptionLink>
          <span className="ml-1">({subscription.businessModel})</span>
        </div>
      : <span>Inactive</span>
  )
}

const CommitmentLink: FC<{
  commitmentCreditCustomerId: string,
  currentCommitmentCredit?: number
}> = ({ commitmentCreditCustomerId, currentCommitmentCredit }) => {
  return (
    <span>
      Commitment credit:
      <Link href={stripe.getCustomerBalanceTransactionsUrl(commitmentCreditCustomerId)}>
        ${currentCommitmentCredit ? currentCommitmentCredit / 100 : "-"}
      </Link>
    </span>
  )
}

type LicensesMap = Map<string, License>;

/**
 * Converts licenses list to a map. If originalLicensesState is passed, the resulting map will prefer a license from it
 * if such exists. This is to ensure that the local state changes will remain even when the state on the server changes.
 * E.g. changing multiple licenses parameters but only patching one of them - the other changed licenses are still the
 * same on the server but the local state is different.
 * @param licenses Licenses passed as component props.
 * @param originalLicensesState Licenses from the state of the component.
 */
function licensesListToStateMap(licenses: License[], originalLicensesState?: LicensesMap): LicensesMap {
  return new Map(licenses.map(l => {
    let license = l;
    if (originalLicensesState && originalLicensesState.has(l.licenseId)) {
      license = originalLicensesState.get(l.licenseId)!;
    }
    return [license.licenseId, license]
  }));
}

function getValidTillDatePartStr(date?: Date): string | undefined {
  return date?.toString().substring(0, 10)
}

/**
 * Always use `handleChange` or `setLicensesState` after a change to a license. This changes the local `licensesState`
 * of the component which takes precedence over passed `licenses` list. See {@link licensesListToStateMap} for more info.
 */
const LicensesTable: FC<{
  licenses: License[],
  accounts: Account[],
  onPatchLicense: (params: PatchLicenseParams) => Promise<License>,
  onDeleteLicense: (licenseId: string) => Promise<void>,
  onDeactivateSubscription: (licenseId: string) => Promise<void>,
}> = ({ licenses, accounts, onPatchLicense, onDeleteLicense, onDeactivateSubscription }) => {
  const [inProgressLicenseIds, setInProgressLicenseIds] = useState<string[]>([]);
  const [licensesState, setLicensesState] = useState(licensesListToStateMap(licenses));
  useEffect(
    () => setLicensesState(licensesState => licensesListToStateMap(licenses, licensesState)),
    [licenses]
  );

  const handleChange = (licenseId: string, field: string, value: string | number | Account | Subscription ) => {
    setLicensesState(previousState => {
        const updatedLicense = { ...previousState.get(licenseId)!, [field]: value };
        return new Map(previousState).set(licenseId, updatedLicense);
      }
    );
  };
  const handleAccountChange = (licenseId: string, accountId: string) => {
    const account = accounts.find(a => a.id === accountId);
    if (account) {
      handleChange(licenseId, "account", account);
    }
  }

  async function inProgressWrapper(licenseId: string, f: () => Promise<any>) {
    setInProgressLicenseIds([...inProgressLicenseIds, licenseId]);
    await f();
    setInProgressLicenseIds(inProgressLicenseIds.filter(id => id !== licenseId));
  }
  const onPatchButtonClicked = async (licenseId: string) => {
    const license = licensesState.get(licenseId);
    let validTillDate: Date | undefined;
    if (license?.validTill) {
      validTillDate = new Date(license?.validTill);
      validTillDate.setUTCHours(23, 59, 59);
    }
    inProgressWrapper(licenseId, () => onPatchLicense({
      licenseId,
      accountId: isAccountChanged(licenseId) ? license?.account?.id : undefined,
      description: isDescriptionChanged(licenseId) ? license?.description : undefined,
      series: isSeriesChanged(licenseId) ? license?.series : undefined,
      validTill: isValidTillChanged(licenseId) ? validTillDate : undefined,
      maxPipelines: isMaxPipelinesChanged(licenseId) ? license?.maxPipelines : undefined,
    }));
  }
  const onDeleteButtonClicked = async (licenseId: string) => {
    inProgressWrapper(licenseId, () => onDeleteLicense(licenseId));
  }
  const onDeactivateSubscriptionButtonClicked = async (license: License) => {
    if (hasNonZeroCommitment(license)) {
      if (!window.confirm('Do you really want to deactivate the subscription?'
        + `\nThere is a commitment credit of $${license.subscription.currentCommitmentCredit! / 100} that will be lost.`
      )) {
        return Promise.resolve()
      }
    }
    await inProgressWrapper(license.licenseId, () => onDeactivateSubscription(license.licenseId));
    handleChange(license.licenseId, "subscription", { isActive: false });
  }

  const findOriginalLicense = (licenseId: string) => licenses.find(l => l.licenseId === licenseId);
  const isAccountChanged = (licenseId: string) => licensesState.get(licenseId)?.account?.id !== findOriginalLicense(licenseId)?.account?.id;
  const isDescriptionChanged = (licenseId: string) => licensesState.get(licenseId)?.description !== findOriginalLicense(licenseId)?.description;
  const isSeriesChanged = (licenseId: string) => licensesState.get(licenseId)?.series !== findOriginalLicense(licenseId)?.series;
  const isValidTillChanged = (licenseId: string) => getValidTillDatePartStr(licensesState.get(licenseId)?.validTill) !== getValidTillDatePartStr(findOriginalLicense(licenseId)?.validTill);
  const isMaxPipelinesChanged = (licenseId: string) => licensesState.get(licenseId)?.maxPipelines !== findOriginalLicense(licenseId)?.maxPipelines;

  const isLicenseInputsUnchanged = (licenseId: string) =>
    !isAccountChanged(licenseId)
    && !isDescriptionChanged(licenseId)
    && !isSeriesChanged(licenseId)
    && !isValidTillChanged(licenseId)
    && !isMaxPipelinesChanged(licenseId);

  return (
    <Table>
      <Table.Header>
        <Table.HeaderCell>Account</Table.HeaderCell>
        <Table.HeaderCell>License key</Table.HeaderCell>
        <Table.HeaderCell>Subscription</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Series</Table.HeaderCell>
        <Table.HeaderCell>Valid Till</Table.HeaderCell>
        <Table.HeaderCell>Max Pipelines</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {
          Array.from(licensesState.values()).map(l =>
          <Table.Row key={l.licenseId}>
            <Table.Cell>
              <AccountSelector name="account" selected={l.account} accounts={accounts} valueProvider={a => a.id}
                               onChange={(e) => handleAccountChange(l.licenseId, e.currentTarget.value)}
                               className="w-40"
              />
            </Table.Cell>
            <Table.Cell>
              <CopyableText text={l.licenseKey}/>
            </Table.Cell>
            <Table.MultiRowCell>
              <SubscriptionLink subscription={l.subscription}/>
              {l.subscription.isActive && l.subscription.commitmentCreditCustomerId &&
                <CommitmentLink commitmentCreditCustomerId={l.subscription.commitmentCreditCustomerId}
                                currentCommitmentCredit={l.subscription.currentCommitmentCredit}/>
              }
              {l.subscription.isActive
                ? <Button disabled={inProgressLicenseIds.includes(l.licenseId)}
                          onClick={() => onDeactivateSubscriptionButtonClicked(l)}>
                    Deactivate
                  </Button>
                : <ActivateSubscriptionButton disabled={inProgressLicenseIds.includes(l.licenseId)} license={l}
                    onActivationSuccess={(licenseId, subscription) => handleChange(licenseId, "subscription", subscription)}
                  >Activate</ActivateSubscriptionButton>
              }
            </Table.MultiRowCell>
            <Table.MultiRowCell>
              <Input name="description" title="description" value={l.description}
                     onChange={(e) => handleChange(l.licenseId, "description", e.currentTarget.value)}
              />
              <span>({l.licenseId})</span>
            </Table.MultiRowCell>
            <Table.Cell>
              <SeriesSelector name="series" selected={l.series} unknownSeries={true} className="w-18"
                              onChange={(e) => handleChange(l.licenseId, "series", e.currentTarget.value)}
              />
            </Table.Cell>
            <Table.Cell>
              <Input type="date" name="validTill" title="validTill" value={getValidTillDatePartStr(l.validTill)} className="w-28"
                     onChange={(e) => handleChange(l.licenseId, "validTill", e.currentTarget.value)}
              />
            </Table.Cell>
            <Table.Cell>
              <Input type="number" name="maxPipelines" title="maxPipelines" value={l.maxPipelines.toString()} className="w-16"
                     onChange={(e) => handleChange(l.licenseId, "maxPipelines", parseInt(e.currentTarget.value))}
              />
            </Table.Cell>
            <Table.Cell>
              <div className="flex gap-2">
                <Button disabled={inProgressLicenseIds.includes(l.licenseId) || isLicenseInputsUnchanged(l.licenseId)}
                        onClick={() => onPatchButtonClicked(l.licenseId)}>
                  &#9989;
                </Button>
                <Button disabled={inProgressLicenseIds.includes(l.licenseId)}
                        onClick={() => onDeleteButtonClicked(l.licenseId)}>
                  &#128465;
                </Button>
                <LicenseStatsButton licenseKey={l.licenseKey}/>
                <LicenseMonitoringButton licenseKey={l.licenseKey}/>
                <DockerComposeButton licenseKey={l.licenseKey}/>
              </div>
            </Table.Cell>
            <Table.Cell>{inProgressLicenseIds.includes(l.licenseId) ? <Loader size={5}/> : null} </Table.Cell>
          </Table.Row>)
        }
      </Table.Body>
    </Table>
  );
}

function hasNonZeroCommitment(license: License): boolean {
  return license.subscription.isActive ?
    (license.subscription.currentCommitmentCredit ?? 0) > 0 :
    false;
}

export default LicensesTable;
