import React, { FC, ReactNode } from 'react';
import clsx from "clsx";
import { CloseButton } from "./Button";

const MessageBox: FC<{
  children: ReactNode,
  onClose: () => void,
  colorClass?: string,
}> = ({children, onClose, colorClass = "text-brand-gray-700"}) => {
  return (
    <div id="result-text"
         className={clsx(
           "empty:hidden whitespace-pre-wrap fixed bottom-12 right-12 p-5 min-w-[200px] bg-brand-gray-100 border border-black",
           colorClass
         )}>
      <CloseButton onClick={onClose} className="absolute top-px right-px pr-1 text-base"/>
      {children}
    </div>
  );
}

export default MessageBox;
