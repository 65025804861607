import React, {FC} from 'react';
import {useOutletContext} from "react-router-dom";
import { getAccount } from "../api/api";
import {OutletContext} from "./Root";
import {useQuery} from "@tanstack/react-query";
import Loader from "./Loader";
import {AccountDetailsLink} from "./AllAccountsTable";
import {Account, AccountSupport} from "../api/Account";
import StripeCustomerLink from "./StripeCustomerLink";
import {AccountStatsButton} from "./Button";

export const AccountDetailHeader: FC<{
  account: Account,
}> = ({ account }) => {
  return (
      <div>
        {AccountSupport.isParent(account) ?
            <ParentAccountHeader account={account} />:
            <ChildAccountHeader parentAccountId={account.parentId}/>
        }
      </div>
  );
}

const ParentAccountHeader: FC<{
  account: Account,
}> = ({ account }) => {
   return (
    <div>
      <div>
        <StripeCustomerLink customerId={account.customerId}>Stripe customer</StripeCustomerLink>
      </div>
      <div>
        License usage statistics: <AccountStatsButton accountName={account.name}/>
      </div>
    </div>
  );
}

const ChildAccountHeader: FC<{
  parentAccountId: string | undefined,
}> = ({ parentAccountId }) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const parentAccountQuery = useQuery({
    queryKey: ['parentAccount', parentAccountId],
    queryFn: () => getAccount(parentAccountId!),
  })
  if (parentAccountQuery.isError) {
    setMessageBoxContent(parentAccountQuery.error);
    return null;
  }
  if (parentAccountQuery.isLoading) {
    return (<Loader />);
  }

  return (
      <div>
        <div>
          Parent account: <AccountDetailsLink account={parentAccountQuery.data!}/>
        </div>
        <div>
          <StripeCustomerLink customerId={parentAccountQuery.data!.customerId}>{parentAccountQuery.data!.name}'s Stripe customer</StripeCustomerLink>
        </div>
        <div>
          License usage statistics: <AccountStatsButton accountName={parentAccountQuery.data!.name}/>
        </div>

      </div>
  );
}

export default AccountDetailHeader;
