import {Account} from "./Account";

// DO NOT DELETE: special comment to locate when adding new series
export enum LicenseSeries {
  Series100 = '100',
  Series300 = '300',
  Series500 = '500',
  Series501 = '501',
  Series700 = '700',
  Series701 = '701',
}

export enum BusinessModel {
  PerHour = 'per_hour',
  MaxPerMonth = 'max_per_month'
}

export interface Subscription {
  isActive: boolean,
  subscriptionId?: string,
  businessModel?: BusinessModel,
  currentCommitmentCredit?: number,
  commitmentCreditCustomerId?: string
}

export interface Discount {
  // Discount percentage with maximum precision of 2 decimal places, expressed as an integer e.g. 1023 is 10.23%.
  percentageBasisPoints?: number,

  //Discount fixed amount. Denominated in a currency's smallest unit e.g. cents for USD. So 4321 is $43.21.
  fixedAmount?: number,

  // Discount duration in months, if not present, discount is applied forever
  durationMonths?: number,

  // Commitment credit amount. Denominated in a currency's smallest unit e.g. cents for USD. So 1200000 is $12000.
  commitmentCredit?: number
}

export interface PatchLicenseParams {
  licenseId: string,
  series?: LicenseSeries,
  description?: string,
  maxPipelines?: number,
  validTill?: Date,
  accountId?: string
}

export interface License {
  licenseId: string,
  licenseKey: string,
  name: string,
  series: LicenseSeries,
  description: string,
  shortDescription: string,
  maxPipelines: number,
  validTill: Date
  subscription: Subscription,
  account?: Account, // optional to avoid recursion
  parentAccount?: Account, // optional to avoid recursion
  codebaseVersion: string
}

export default License;
