import React, { FC } from 'react';
import {Account, AccountSupport} from "../api/Account";
import { AccountStatsButton } from "./Button";
import StripeCustomerLink from "./StripeCustomerLink";
import Table, { RowChildren } from "./Table";
import {Link} from "react-router-dom";

const AllAccountsTable: FC<{
  accounts: Account[]
}> = ({accounts}) => {
  return (
    <div>
      <Table>
        <AccountTableHeader />
        <Table.Body>
          {
            AccountSupport.getParentAccounts(accounts)
              .sort(AccountSupport.compareByName)
              .map((a) => <AccountAndChildRows
                account={a}
                childAccounts={AccountSupport.getChildAccountsOf(accounts, a)}
                key={a.id}
              />)
          }
        </Table.Body>
      </Table>
    </div>
  );
};

export const ChildAccountsTable: FC<{
  accounts: Account[]
}> = ({accounts}) => {
  return (
    <div>
      <Table>
        <ChildAccountTableHeader />
        <Table.Body>{
            accounts.map((a) =>
              <AccountRow account={a} key={a.id}>
                <Table.Cell><AccountDetailsLink account={a} /></Table.Cell>
              </AccountRow>)
        }</Table.Body>
      </Table>
    </div>
  );
};

const AccountTableHeader = () => (
  <Table.Header>
    <Table.HeaderCell title="The name of the account.">Account</Table.HeaderCell>
    <Table.HeaderCell title="A link to the Stripe customer. '-' stands for no customer.">Stripe customer</Table.HeaderCell>
    <Table.HeaderCell></Table.HeaderCell>
  </Table.Header>
);

const ChildAccountTableHeader = () => (
  <Table.Header>
    <Table.HeaderCell title="The name of the account.">Account</Table.HeaderCell>
    <Table.HeaderCell></Table.HeaderCell>
  </Table.Header>
);

const AccountAndChildRows: FC<{
  account: Account,
  childAccounts: Account[]
}> = ({ account, childAccounts }) => {
  return (
    <React.Fragment>
      <ParentAccountRow account={account} key={account.id} />
      {
        childAccounts.sort(AccountSupport.compareByName)
          .map((a) => <ChildAccountRow account={a} key={a.id} />)
      }
    </React.Fragment>
  );
};

const AccountRow: FC<{
  account: Account,
  children: RowChildren
}> = ({ account, children }) => {
  return (
    <Table.Row>
      <React.Fragment>
        {children}
        <Table.Cell>
          <AccountStatsButton accountName={account.name}/>
        </Table.Cell>
      </React.Fragment>
    </Table.Row>
  )
};

const ParentAccountRow: FC<{ account: Account }> = ({ account }) => {
  return (
    <AccountRow account={account} >
      <Table.Cell><AccountDetailsLink account={account} /></Table.Cell>
      <Table.Cell><StripeCustomerLink customerId={account.customerId}>Stripe customer</StripeCustomerLink></Table.Cell>
    </AccountRow>
  )
}

const ChildAccountRow: FC<{ account: Account }> = ({ account }) => {
  return (
    <AccountRow account={account}>
      <Table.Cell colSpan={2} className="pl-20"><AccountDetailsLink account={account} /></Table.Cell>
    </AccountRow>
  )
}

export const AccountDetailsLink: FC<{ account: Account }> = ({ account }) => (
  <Link to={`/accounts/${account.id}`}>{account.name}</Link>
);

export default AllAccountsTable;
