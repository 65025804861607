import React, { FC, ReactNode } from "react";

export const Link: FC<{
  href: string;
  className?: string,
  children: ReactNode;
}> = ({ href, className, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className={className}>{children}</a>
);

export default Link;
