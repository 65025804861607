import React, { FC } from 'react';
import Input from "./Input";

const MaxPipelinesInput: FC<{
  name: string,
  required?: boolean,
  className?: string,
}> = ({ name, required = true, className }) => {
  return (
    <Input type="number" name={name} title="Max pipelines" required={required} defaultValue={16} min="1" step="1"
           className={className}/>
  );
}

export default MaxPipelinesInput;
