import React, { forwardRef } from 'react';
import clsx from "clsx";

const Input = forwardRef((
  props: React.HTMLProps<HTMLInputElement>,
  ref: React.ForwardedRef<HTMLInputElement> | undefined,
) => {
  return (
    <input {...props} type={props.type === undefined ? "text" : props.type}
           className={clsx("mx-1 pl-1 border border-brand-gray-200 outline-brand-blue-500 outline-2", props.className)}
           ref={ref}
    />
  );
});

export default Input;
