import React from "react";
import clsx from "clsx";

interface SelectProps<T> {
  options: T[],
  idProvider: (option: T) => string,
  valueProvider: (option: T) => string,
  displayValueProvider: (option: T) => string,
  name?: string,
  selectedOption?: T,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>,
  className?: string,
}

function Select<T>({
                     options,
                     idProvider,
                     valueProvider,
                     displayValueProvider,
                     name,
                     selectedOption,
                     onChange,
                     className
                   }: SelectProps<T>) {
  return (
    <select name={name}
            className={clsx("mx-1 border border-brand-gray-200 outline-brand-blue-500 outline-2", className)}
            defaultValue={selectedOption && valueProvider(selectedOption)}
            onChange={onChange}
    >
      {options.map(o => (
        <option key={idProvider(o)} value={valueProvider(o)}>{displayValueProvider(o)}</option>
      ))}
    </select>
  );
}

export default Select;
