import React, {FC, useRef} from "react";
import SubHeading from "./SubHeading";
import {ChildAccountsTable} from "./AllAccountsTable";
import Input from "./Input";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createAccount, getChildAccounts} from "../api/api";
import {useOutletContext} from "react-router-dom";
import {OutletContext} from "./Root";
import Loader from "./Loader";
import {Account} from "../api/Account";
import Button from "./Button";

const ChildAccounts: FC<{
  accountId: string
  isParent: boolean
}> = ({ accountId, isParent }) => (
    <React.Fragment>
      <SubHeading headingText={"Child Accounts"} />
      {
        isParent ?
          <LoadedChildAccounts parentAccountId={accountId} /> :
          <p>This account is a child, it cannot have any nested children.</p>
      }
    </React.Fragment>
);

const LoadedChildAccounts: FC<{
  parentAccountId: string
}> = ({ parentAccountId }) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const { isPending, isError, data, error } = useQuery({
    queryKey: buildQueryKey(parentAccountId),
    queryFn: () => getChildAccounts(parentAccountId),
  })
  if (isError) {
    setMessageBoxContent(error.message);
    return null;
  }
  if (isPending) {
    return (<Loader />);
  }
  return (
    <React.Fragment>
      <ChildAccountsTable accounts={data!} />
      <CreateChildAccountForm parentAccountId={parentAccountId}/>
    </React.Fragment>
  );
}

const CreateChildAccountForm: FC<{
  parentAccountId: string
}>= ({ parentAccountId }) => {
  const queryClient = useQueryClient();
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const formRef = useRef<HTMLFormElement>(null);
  const createAccountMutation = useMutation({
    mutationFn: (data: FormData) => createAccount(
      FormFields.getName(data),
      FormFields.getParentAccountId(data)
    ),
    onSuccess: (account) => {
      queryClient.setQueryData(
        buildQueryKey(account.parentId!),
        (data: Account[]) => [ ...data, account ]
      );
      formRef.current!.reset();
    },
    onError: (error) => {
      setMessageBoxContent(error);
    }
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessageBoxContent(undefined);
    createAccountMutation.mutate(new FormData(e.currentTarget));
  };

  return (
    <form ref={formRef} onSubmit={onSubmit} className="mt-2">
      <label>Child account name
      <Input name={FormFields.name} pattern=".*\w.*" title="Has to contain at least a single letter."/>
      </label>
      <div className="mt-2 flex items-center">
        <Button type="submit" disabled={createAccountMutation.isPending} title="Create a new child account."
        >Create</Button>
        { createAccountMutation.isPending && <Loader size={5} className="ml-2" /> }
      </div>
      <input type="hidden" name={FormFields.parentAccountId} value={parentAccountId} />
    </form>
  );
}

function buildQueryKey(parentAccountId: string) {
  return ['child-accounts', parentAccountId];
}

const FormFields = {
  name: "name",
  parentAccountId: "parentAccountId",
  getName: (data: FormData) => data.get(FormFields.name) as string,
  getParentAccountId: (data: FormData) => data.get(FormFields.parentAccountId) as string
};

export default ChildAccounts;
