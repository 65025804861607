import React, { FC } from 'react';
import Input from "./Input";
import clsx from "clsx";

const LicenseKeyInput: FC<{
  name: string,
  required?: boolean,
  className?: string,
}> = ({ name, required = false, className }) => {
  return (
    <Input name={name} title="License key" required={required}
           pattern="(?:^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$)|(?:^$)"
           className={clsx("w-80", className)}/>
  );
}

export default LicenseKeyInput;
