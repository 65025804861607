import React, {FC, useRef} from "react";
import {useOutletContext} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {createUser, getAccountUsers} from "../api/api";
import User from "../api/User";
import {OutletContext} from "./Root";
import SubHeading from "./SubHeading";
import Loader from "./Loader";
import UsersTable from "./UsersTable";
import Input from "./Input";
import Button from "./Button";

const AccountUsers: FC<{
  accountId: string
}> = ({ accountId }) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const { isPending, isError, data, error } = useQuery({
    queryKey: buildQueryKey(accountId),
    queryFn: () => getAccountUsers(accountId),
  })
  if (isError) {
    setMessageBoxContent(error.message);
    return null;
  }

  return (
    <React.Fragment>
      <SubHeading headingText="Users"/>
      {
        isPending ?
          <Loader /> :
          <UsersTable users={data!} />
      }
      <CreateAccountUserForm accountId={accountId} />
    </React.Fragment>
  );
}

const CreateAccountUserForm: FC<{
  accountId: string
}>= ({ accountId }) => {
  const queryClient = useQueryClient();
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const formRef = useRef<HTMLFormElement>(null);
  const createUserMutation = useMutation({
    mutationFn: (data: FormData) => createUser(
      FormFields.getEmail(data),
      FormFields.getAccountId(data)
    ),
    onSuccess: (user) => {
      queryClient.setQueryData(
        buildQueryKey(accountId),
        (data: User[]) => [ ...data, user ]
      );
      formRef.current!.reset();
    },
    onError: (error) => {
      setMessageBoxContent(error);
    }
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessageBoxContent(undefined);
    createUserMutation.mutate(new FormData(e.currentTarget));
  };

  return (
    <form ref={formRef} onSubmit={onSubmit} className="mt-2">
      <label>User e-mail
        <Input name={FormFields.email}
               pattern="[^@\s]+@[^@\s]+"
               title="Will be used for signup, password will be sent via mail." />
      </label>
      <div className="mt-2 flex items-center">
        <Button type="submit" disabled={createUserMutation.isPending} title="Create a new account user."
        >Create</Button>
        { createUserMutation.isPending && <Loader size={5} className="ml-2" /> }
      </div>
      <input type="hidden" name={FormFields.accountId} value={accountId} />
    </form>
  );
}

function buildQueryKey(accountId: string) {
  return ['account-users', accountId];
}

const FormFields = {
  email: "email",
  accountId: "accountId",
  getEmail: (data: FormData) => data.get(FormFields.email) as string,
  getAccountId: (data: FormData) => data.get(FormFields.accountId) as string
};

export default AccountUsers;
