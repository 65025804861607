import React, { useRef, useEffect } from "react";
import { CloseButton } from "./Button";

interface ModalProps {
    isOpen: boolean;
    hasCloseBtn?: boolean;
    onClose?: () => void;
    children: React.ReactNode;
}

const ModalDialog: React.FC<ModalProps> = ({
                                         isOpen,
                                         hasCloseBtn = true,
                                         onClose,
                                         children
                                     }) => {
    const modalDialogRef = useRef<HTMLDialogElement | null>(null);

    const handleCloseModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === "Escape") {
            handleCloseModal();
        }
    };

    useEffect(() => {
        const modalDialogElement = modalDialogRef.current;

        if (modalDialogElement) {
            if (isOpen) {
                modalDialogElement.showModal();
            } else {
                modalDialogElement.close();
            }
        }
    }, [isOpen]);

    return (
        <dialog ref={modalDialogRef} onKeyDown={handleKeyDown} className="relative max-w-2xl p-8 border-0 rounded-lg backdrop:backdrop-contrast-50">
            {hasCloseBtn && <CloseButton onClick={handleCloseModal}/>}
            {children}
        </dialog>
    );
};

export default ModalDialog;
