import React, {FC, ReactNode, useState} from 'react';
import createMonitoringGroupId from "../api/monitoring-group-id";
import DockerComposeModalDialog from "./DockerComposeModalDialog";
import { useOutletContext } from "react-router-dom";
import { MessageBoxContentSetterType, OutletContext } from "./Root";
import clsx from "clsx";

const Button: FC<{
  type?: "submit" | "reset" | "button";
  title?: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode
}> = ({type = "button", title, disabled = false, onClick, children}) => {
  return (
    <button type={type}
            title={title}
            disabled={disabled}
            onClick={type === "button" ? onClick : undefined}
            className="px-2 py-1 text-sm inline-flex justify-center rounded-md border border-gray-300 bg-white font-medium uppercase leading-4 shadow-sm hover:bg-brand-gray-100 active:bg-white disabled:opacity-40">
      {children}
    </button>
  );
}
export default Button;

export const SubmitButton: FC<{ text: string }> = ({text}) => (
  <Button type="submit" children={text}/>
)

export const CopyButton: FC<{
  valueToCopy: string
}> = ({valueToCopy}) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  return (
    <Button onClick={() => { copyTextToClipboard(valueToCopy, setMessageBoxContent) }}>
      &#128203;
    </Button>
  );
}

export const LicenseStatsButton: FC<{ licenseKey: string }> = ({licenseKey}) => (
  <Button title="Shows usage statistics of the license." onClick={() => openUsageStatistics(undefined, licenseKey)}>
    &#128202;
  </Button>
);

export const LicenseMonitoringButton: FC<{ licenseKey: string }> = ({licenseKey}) => (
  <Button title="Opens Monitoring Dashboard with pipelines running under the license." onClick={() => {
    const url = new URL("/", process.env.REACT_APP_MONITORING_URL);
    createMonitoringGroupId(licenseKey)
      .then((monitoringGroupId) => {
        url.searchParams.set("monitoring_group_id", monitoringGroupId);
        window.open(url, '_blank')
      });
  }}>
    &#128301;
  </Button>
);

export const AccountStatsButton: FC<{ accountName: string }> = ({accountName}) => (
  <Button title="Shows usage statistics of the account licenses." onClick={() => openUsageStatistics(accountName)}>
    &#128202;
  </Button>
);

function openUsageStatistics(accountName?: string, licenseKey?: string) {
  const url = new URL("/dashboards/4-moneybox-license-usage", process.env.REACT_APP_STATS_URL);
  url.searchParams.set("p_account_names", `["${accountName ?? "All"}"]`);
  url.searchParams.set("p_license_keys", `["${licenseKey ?? "All"}"]`)
  url.searchParams.set("p_pipeline_state", "Granted")
  url.searchParams.set("p_w53_time_granularity", "day")
  url.searchParams.set("p_w54_time_granularity", "day")
  url.searchParams.set("p_w56_feature_state", "requested")
  url.searchParams.set("p_w57_feature_state", "requested")
  url.searchParams.set("p_w58_feature_state", "invalid")
  const date = new Date();
  url.searchParams.set("p_to", "d_now")
  date.setDate(date.getDate() - 7) // last 7 days
  url.searchParams.set("p_from", `${date.toISOString().substring(0, 10)} 00:00`)
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const CloseButton: FC<{ onClick: () => void, className?: string }> = ({onClick, className}) => (
  <button onClick={onClick} className={clsx("absolute top-2 right-4 text-xl text-brand-gray-500 hover:text-brand-gray-800", className)}>
    &#x2715;
  </button>
);

export const WgetButton: FC<{ url: string }> = ({ url}) => {
  const { setMessageBoxContent } = useOutletContext() as OutletContext;
  const wgetCommand = `wget -O docker-compose.yml "${url}"`;
  return (
    <Button onClick={() => { copyTextToClipboard(wgetCommand, setMessageBoxContent) }}>
      wget
    </Button>
  );
}

export const DockerComposeButton: FC<{ licenseKey: string }> = ({licenseKey}) => {
    const [isDockerComposeModalDialogOpen, setDockerComposeModalDialogOpen] = useState<boolean>(false);

    const handleOpenDockerComposeModalDialog = () => {
        setDockerComposeModalDialogOpen(true);
    };

    const handleCloseDockerComposeDialog = () => {
        setDockerComposeModalDialogOpen(false);
    };

    return (
        <React.Fragment>
            <Button title="Shows dialog that generates docker-compose.yml link." onClick={handleOpenDockerComposeModalDialog}>
                &#128011;
            </Button>

            <DockerComposeModalDialog
                licenseKey={licenseKey}
                isOpen={isDockerComposeModalDialogOpen}
                onClose={handleCloseDockerComposeDialog} />
        </React.Fragment>
    );
};

function copyTextToClipboard(text: string, setMessageBoxContent: MessageBoxContentSetterType) {
  window.navigator.clipboard.writeText(text)
    .then(() => {
      setMessageBoxContent(`Copied '${text}' to clipboard`, 3000);
    })
    .catch(() => setMessageBoxContent(`Failed to copy '${text}' to clipboard`));
}

